.App {
  font-family: monospace;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

.footer {
  margin-bottom: 100px;
}

.footer2, .header2 {
  margin-bottom: 75px;
  margin-top: 75px;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}

.button {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px white solid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  cursor: pointer;
}

.button.active {
  background: white;
  color: #222;
}

.score {
}

.sum {
  margin-top: 30px;
}

.target {
  font-size: calc(24px + 2vmin);
}

.t0 {
  animation: grow 200ms forwards;
}

.t1 {
  animation: grow2 200ms forwards;
}

.vflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left, .right {
  min-width: 10vw;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.reload {
}

.longbtn {
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 50px;
}

.hint{
}

.timer {
}

@keyframes grow2 {
  50% {
    transform: scale(1.5);
  }
}

@keyframes grow {
  50% {
    transform: scale(1.5);
  }
}
